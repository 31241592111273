import { BellIcon } from "@heroicons/react/24/outline";
import React, { Suspense, useCallback, useState } from "react";
import { Link } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import resolveConfig from "tailwindcss/resolveConfig";
import { useIntersectionObserver } from "usehooks-ts";

import tailwindConfig from "@/../tailwind.config";
import { colors } from "@/styles/global.styles";
import cn from "@/utils/cn";
import {
  isAndroid,
  isIOS,
  isMobile,
  isSmartTVUserAgent,
  openVerseDownloadAppAndroid,
  openVerseDownloadAppIOS,
} from "@/utils/helpers/Utils";
import arrow3 from "../../assets/onboardingScreen/arrow-3.png";

import call from "../../assets/onboardingScreen/verse-images-optimized/call.svg";
import chat from "../../assets/onboardingScreen/verse-images-optimized/chat.svg";
import chatbot from "../../assets/onboardingScreen/verse-images-optimized/chatbot.svg";
import syncCloud from "../../assets/onboardingScreen/verse-images-optimized/cloud.svg";
import devices from "../../assets/onboardingScreen/verse-images-optimized/devices.svg";
import login from "../../assets/onboardingScreen/verse-images-optimized/login.svg";
import odience from "../../assets/onboardingScreen/verse-images-optimized/odience.svg";
import qrcode from "../../assets/onboardingScreen/verse-images-optimized/qrcode.svg";
import shared from "../../assets/onboardingScreen/verse-images-optimized/shared.svg";

import odienceImg from "../../assets/onboardingScreen/verse-images-optimized/img-odience.png";

import top from "../../assets/onboardingScreen/verse-images-optimized/top.png";
import verseLogo from "../../assets/onboardingScreen/verse-images-optimized/verse-logo.svg";
import versewhite from "../../assets/onboardingScreen/verse_white.png";

import ChatbotCarousel from "@/components/onboarding/components/ChatbotCarousel";
import VideoPlayer from "@/components/onboarding/components/VideoPlayer";
import { paths } from "../../routerPaths";

function importOverlay() {
  return import("../../components/onboarding/components/GetTheAppOverlay");
}
const Overlay = React.lazy(importOverlay);

const twConfig = resolveConfig(tailwindConfig);
const versePromoUrl = "https://vimeo.com/1018675279/7853c9644a";
const isSmartTV = isSmartTVUserAgent(navigator.userAgent);
export default function OnboardingDesktop() {
  const [overlayOpen, setOverlayOpen] = useState(false);
  const toggleOverlay = useCallback(() => {
    setOverlayOpen((prev) => !prev);
  }, []);

  const handleGetAppClicked = () => {
    if (isAndroid()) {
      openVerseDownloadAppAndroid();
    } else if (isIOS()) {
      openVerseDownloadAppIOS();
    } else {
      toggleOverlay();
    }
  };

  return (
    <div className="flex min-h-screen flex-col items-center bg-onboardingPrimaryBg font-[Figtree,Helvetica]">
      <Header handleGetAppClicked={handleGetAppClicked} />
      <Content handleGetAppClicked={handleGetAppClicked} />
      {overlayOpen && (
        <Suspense fallback={<div>Loading...</div>}>
          <Overlay toggleOverlay={toggleOverlay} />
        </Suspense>
      )}
    </div>
  );
}

function SectionWithInView({ children }: { children: React.ReactNode }) {
  const { ref, isIntersecting } = useIntersectionObserver({
    freezeOnceVisible: true,
    rootMargin: "-20% 0px",
  });

  return isSmartTV ? (
    <section style={{ width: "100%" }}>{children}</section>
  ) : (
    <section
      ref={ref}
      className={`transform transition-all duration-1000 ease-out ${
        isIntersecting
          ? "translate-y-0 scale-100 opacity-100"
          : "translate-y-8 scale-95 opacity-0"
      }`}
      style={{
        transitionProperty: "opacity, transform",
        width: "100%",
        marginTop: "0.5em",
      }}
    >
      {children}
    </section>
  );
}

// using twMerge to get auto sorting of tailwind classes
const buttonClasses = twMerge(
  "flex h-[4.5rem] min-w-fit max-w-[224px] items-center justify-center gap-2 rounded-[30px] bg-onboardingSecondaryBg p-4 text-left text-primaryTextColor transition-transform active:scale-95 md:h-20 md:min-w-[224px]"
);

function Button(props: React.ComponentProps<"button">) {
  return (
    <button {...props} className={twMerge(buttonClasses, props.className)}>
      {props.children}
    </button>
  );
}

function ButtonLink(props: React.ComponentProps<typeof Link>) {
  return (
    <Link {...props} className={twMerge(buttonClasses, props.className)}>
      {props.children}
    </Link>
  );
}

function GetAppButton(props: React.ComponentProps<typeof Button>) {
  return (
    <Button
      {...props}
      className={twMerge(
        "w-full border-4 border-onboardingPrimaryAccent bg-onboardingPrimaryBg md:w-56",
        props.className
      )}
      onClick={props.onClick}
    >
      <img alt="VERSE" src={versewhite} className="h-6 w-auto md:hidden" />
      <img alt="Qr code" src={qrcode} className="hidden h-6 w-6 md:block" />
      <span className="max-w-min md:max-w-[unset] xl:hidden">
        <span className="whitespace-nowrap text-nowrap text-sm md:text-base">
          Get the
        </span>{" "}
        <span className="font-bold md:font-normal">App</span>
      </span>
      <span className="hidden max-w-min whitespace-nowrap text-nowrap md:max-w-[unset] xl:block">
        Get the Verse App
      </span>
    </Button>
  );
}

function Header({ handleGetAppClicked }: { handleGetAppClicked: () => void }) {
  return (
    <>
      <div
        className={cn(
          `fixed top-0 z-50 flex w-[calc(100%-2rem)] content-between gap-6 pb-10 pt-4 xl:max-w-[80vw]`,
          {
            "pt-10": isMobile(),
          }
        )}
      >
        {/* background */}
        <div className="absolute left-1/2 top-0 -z-10 h-full w-screen -translate-x-1/2 bg-gradient-to-b from-onboardingPrimaryBg from-80% to-transparent to-100%" />

        {isMobile() && (
          <>
            <div className="absolute left-1/2 top-3 flex -translate-x-1/2 items-center gap-2 whitespace-nowrap text-nowrap text-[min(1rem,3.5vw)] text-primaryTextColor">
              <BellIcon className="w-4 stroke-onboardingPrimaryAccent" />
              Web version of Verse available on desktop browsers
            </div>
          </>
        )}

        <div className="flex w-full items-center gap-4 md:w-auto">
          <ButtonLink className="w-full md:w-56" to={paths.previewOdience}>
            <img
              className="h-8 w-8 object-cover"
              alt="Odience logo"
              src={odience}
            />
            <span className="max-w-min md:max-w-[unset]">
              <span className="text-sm md:text-base">Preview</span>{" "}
              <span className="font-bold md:font-normal">Odience</span>
            </span>
          </ButtonLink>
          <div className="hidden whitespace-nowrap text-nowrap font-bold text-onboardingSecondaryText lg:block">
            Odience 360 live streaming. <br />
            Now bundled with Verse!
          </div>
        </div>
        <div className="flex w-full justify-end gap-4">
          <GetAppButton
            onClick={handleGetAppClicked}
            onMouseOver={importOverlay}
            onTouchStart={importOverlay}
          />
          {!isMobile() && (
            <ButtonLink
              to={paths.provisioning}
              className="hidden bg-onboardingPrimaryAccent md:flex"
            >
              <img
                alt="Login"
                src={login}
                className="flex h-6 w-6 object-cover"
              />
              <span>Login Verse Web</span>
            </ButtonLink>
          )}
        </div>
      </div>
      {/* Header spacer since it's fixed */}
      <div className="h-[80px]"></div>
    </>
  );
}

function Content({ handleGetAppClicked }: { handleGetAppClicked: () => void }) {
  return (
    <div
      className="flex max-w-[min(25em,82vw)] flex-col items-center text-onboardingPrimaryText md:max-w-[min(1100px,80vw)]"
      css={{
        height: isSmartTV ? "100vh" : undefined,
        overflowY: isSmartTV ? "auto" : undefined,
        overflowX: isSmartTV ? "hidden" : undefined,
        "::-webkit-scrollbar": {
          width: "6px",
        },
        "::-webkit-scrollbar-track": {
          backgroundColor: "#1c1c1c",
          borderRadius: "6px",
        },
        "::-webkit-scrollbar-thumb": {
          backgroundColor: "#2e3237",
          borderRadius: "6px",
        },
      }}
    >
      <SectionWithInView>
        <Main handleGetAppClicked={handleGetAppClicked} />
      </SectionWithInView>

      {/* //TODO RE ADD WHEN WE HAVE NEW MATERIAL */}
      {/* <SectionWithInView>
        <PromoCarousel />
      </SectionWithInView> */}

      <SectionWithInView>
        <VideoPlayer url={versePromoUrl} />
      </SectionWithInView>

      <SectionWithInView>
        <Features />
      </SectionWithInView>
      <SectionWithInView>
        <Chatbots />
      </SectionWithInView>
      <SectionWithInView>
        <Odience />
      </SectionWithInView>
      <SectionWithInView>
        <Footer handleGetAppClicked={handleGetAppClicked} />
      </SectionWithInView>
    </div>
  );
}

function Main({ handleGetAppClicked }: { handleGetAppClicked: () => void }) {
  return (
    <main className="w-full pb-0 md:pb-0">
      <div className="relative w-full py-10 text-[min(0.681rem,3.5vw)] text-onboardingPrimaryAccentSmallText md:w-1/2 md:text-[min(0.95rem,3.5vw)]">
        <h3 className="flex justify-center whitespace-nowrap text-nowrap py-5 text-center md:justify-start md:py-4 md:text-left">
          Modular{" "}
          <span className="font-bold text-primaryTextColor">
            &nbsp;Unified Communications&nbsp;
          </span>{" "}
          platform ideal for
          <span className="font-bold text-primaryTextColor">
            &nbsp;P5G&nbsp;
          </span>
          & more
        </h3>
        <div className="relative flex w-fit min-w-[min(384px,min(85vw,100%))] justify-center md:justify-start">
          <img
            src={verseLogo}
            alt="Verse"
            width="384"
            className="sm:min-w-[384px]"
          />
          <h4 className="absolute bottom-[-40%] left-[35%] select-none whitespace-nowrap text-nowrap text-[min(0.8rem,3.5vw)] text-onboardingSecondaryText md:text-[min(0.95rem,3.5vw)]">
            powered by{" "}
            <a
              href="https://www.imsrcscloud.com/"
              target="_blank"
              className="text-onboardingTertiaryAccent"
              rel="noreferrer"
            >
              Summit IMS.RCS Cloud
            </a>
          </h4>
        </div>
      </div>
      <div className="flex w-full flex-col-reverse items-center gap-10 md:flex-row md:items-start xl:gap-[min(6vw,200px)]">
        <div className="flex w-full flex-col gap-6">
          <h2 className="text-[max(1rem,min(1.875rem,8.5vw))] font-bold md:text-4xl">
            The Most Versatile
            <br />
            <span className="whitespace-nowrap text-nowrap text-onboardingTertiaryAccent">
              Rich Communications
            </span>
            <br />
            Messaging App
          </h2>
          <p className="max-w-[28em] text-onboardingSecondaryText">
            Available on mobile devices, MacBooks, or the web, you can video
            call, chat, share photos, voice notes, and more. VERSE Messages also
            comes with unique chatbots for gaming, shopping, and many more
            exciting features to come!
          </p>
          <div className="flex w-full justify-start lg:w-auto">
            <GetAppButton
              className="border-none bg-onboardingPrimaryAccent"
              onClick={handleGetAppClicked}
              onMouseOver={importOverlay}
              onTouchStart={importOverlay}
            />
          </div>
          <p className="text-center text-sm md:text-left">
            <span className="text-onboardingPrimaryAccentSmallText">NEW:</span>{" "}
            <span className="font-bold">Verse</span> updated with UP 2.7
            <span className="mx-2">|</span>
            <a
              target="_blank"
              href="https://www.familybot.ai"
              className="font-bold underline"
              rel="noreferrer"
            >
              AI VoiceBot
            </a>{" "}
            now available
          </p>
        </div>
        <div className="w-full max-w-[min(25em,85vw)] pt-8 md:min-w-[325px] md:-translate-y-[20px] md:pt-0 lg:-translate-y-[100px] xl:max-w-[min(30em,80vw)]">
          <img
            src={top}
            className="h-full w-full origin-bottom scale-105 object-cover"
          />
        </div>
      </div>
    </main>
  );
}

export function MultiSizedImage(props: {
  xlSrc: string;
  mdSrc: string;
  src: string;
  imgProps: Omit<React.ComponentProps<"img">, "src">;
  className?: string;
}) {
  return (
    <picture className={props.className}>
      <source
        srcSet={props.xlSrc}
        media={`(min-width:${twConfig.theme.screens.xl})`}
      />
      <source
        srcSet={props.mdSrc}
        media={`(min-width:${twConfig.theme.screens.md})`}
      />
      <img {...props.imgProps} src={props.src} />
    </picture>
  );
}

function Features() {
  return (
    <>
      <div className="ml-4 w-full pb-4 text-2xl font-bold md:pb-8">
        <span css={{ color: colors.primaryAccentColor }}>VERSE</span> is a
        Unified Communication platform enabling RCS voice, video, and messaging
        services on mobile, desktop & web browsers.
      </div>
      <div className="grid grid-cols-1 grid-rows-6 gap-4 pb-16 md:pb-24 xl:grid-cols-3 xl:grid-rows-2">
        <Feature
          icon={{ src: chat, alt: "Group Chat" }}
          title="Rich Chat, Group Chat"
          description="Send rich text messages, photos, voice notes & more"
        />
        <Feature
          icon={{ src: call, alt: "Video Call" }}
          title="Enriched Call, Video Call"
          description="Enhanced Voice & Video calling sets a new standard for digital communication"
        />
        <Feature
          icon={{ src: syncCloud }}
          title="Message Sync"
          description="Retrieve your messages from smartphones, desktop browsers, and even on VR headsets"
        />
        <Feature
          icon={{ src: devices }}
          title="Multi Device"
          description="Smartphones, tablets, desktops & web browsers"
        />
        <Feature
          icon={{ src: shared }}
          title="Shared Map, Shared Sketch"
          description="Share you location in real-time, or start whiteboard sessions"
        />
        <Feature
          icon={{ src: chatbot }}
          title="Chatbots"
          description="Interact with mini-apps & services directly within your chat session"
        />
      </div>
    </>
  );
}

type FeatureProps = {
  icon: React.ComponentProps<"img">;
  title: string;
  description: string;
};
function Feature({ icon, title, description }: FeatureProps) {
  return (
    <div className="flex max-w-[min(40em,90vw)] items-center gap-4 rounded-[30px] border-2 border-onboardingQuaternaryBg px-8 py-6 md:px-12 md:py-8 xl:flex-col">
      <div className="h-[var(--icon-size)] max-h-[var(--icon-size)] w-auto min-w-[var(--icon-size)] max-w-[var(--icon-size)] [--icon-size:50px] md:[--icon-size:65px] xl:[--icon-size:75px]">
        <img
          {...icon}
          alt={icon.alt || title}
          className="h-full w-full object-contain"
        />
      </div>
      <div>
        <h4 className="font-bold md:text-lg">{title}</h4>
        <p className="text-onboardingSecondaryText">{description}</p>
      </div>
    </div>
  );
}

function Chatbots() {
  return (
    <section className="w-full pb-16">
      <div>
        <h2 className="text-4xl font-bold leading-normal">
          Unique{" "}
          <span className="text-onboardingQuaternaryAccent">Chatbots</span>
        </h2>
        <p className="text-base text-onboardingSecondaryText md:text-lg">
          Shopping, gaming, IoT, AI & more to explore through Verse's RCS
          chatbots
        </p>
      </div>

      <ChatbotCarousel />
    </section>
  );
}

function Odience() {
  return (
    <section className="w-full pb-16 md:pb-24">
      <div className="flex w-full flex-col items-center justify-between gap-4 md:flex-row md:gap-32">
        <div className="w-full">
          <h2 className="text-4xl font-bold leading-normal">
            Odience{" "}
            <span className="whitespace-nowrap text-nowrap text-onboardingSecondaryAccent">
              360 Live streaming
            </span>
          </h2>
          <p className="text-lg text-onboardingSecondaryText">
            Immersive 8K 360 live stream from home, stadiums, showrooms, or
            virtual reality. Our exclusive features transform any 360 live
            stream into a unique social event!
          </p>
        </div>
        <ButtonLink
          to="https://odience.com"
          target="_blank"
          className="relative flex w-full max-w-[unset] justify-around bg-onboardingPrimaryAccent pl-8 text-sm font-bold uppercase leading-normal tracking-[4.2px] md:w-auto md:min-w-[224px]"
        >
          <div className="flex items-center gap-2">
            <img
              className="h-8 w-8 object-cover"
              alt="Odience logo"
              src={odience}
            />
            Odience
          </div>
          <div className="flex items-center justify-center">
            <img
              src={arrow3}
              // className="absolute right-4 top-1/2 w-2 -translate-y-1/2"
              className="min-w-3 max-w-3"
            />
          </div>
        </ButtonLink>
      </div>

      <div className="py-4">
        <MultiSizedImage
          xlSrc={odienceImg}
          mdSrc={odienceImg}
          src={odienceImg}
          imgProps={{ alt: "Odience" }}
        />
      </div>
    </section>
  );
}

function Footer({ handleGetAppClicked }: { handleGetAppClicked: () => void }) {
  return (
    <footer className="w-full pb-16 md:pb-24">
      <div className="flex w-full flex-col items-center justify-center rounded-[min(12vw,80px)] bg-onboardingSecondaryBg p-12 md:p-16">
        <div className="flex items-center justify-center">
          <img src={verseLogo} className="h-8 w-auto" />
        </div>
        <p className="max-w-full py-6 text-center text-lg text-onboardingSecondaryText md:w-96">
          Multi-device messaging, voice and video call, chatbots and live
          events.
        </p>
        <GetAppButton
          className="border-none bg-onboardingPrimaryAccent"
          onClick={handleGetAppClicked}
          onMouseOver={importOverlay}
          onTouchStart={importOverlay}
        />
        <div className="mt-8 block items-center justify-between text-center text-sm text-gray-500 md:flex">
          <p className="whitespace-nowrap text-nowrap">
            © VERSE Messages&nbsp;&nbsp;•&nbsp;&nbsp;All rights reserved
            <span className="hidden md:inline-block">
              &nbsp;&nbsp;•&nbsp;&nbsp;
            </span>
          </p>
          <Link
            to={paths.eula}
            className="whitespace-nowrap text-nowrap text-onboardingSecondaryAccent hover:underline"
          >
            Terms of Service
          </Link>
          &nbsp;&nbsp;•&nbsp;&nbsp;
          <Link
            to={paths.privacyPolicy}
            className="whitespace-nowrap text-nowrap text-onboardingSecondaryAccent hover:underline"
          >
            Privacy Policy
          </Link>
        </div>
      </div>
    </footer>
  );
}
